import { useAuth0 } from '@auth0/auth0-react'
import { RouteComponentProps } from '@reach/router'
import React from 'react'
import BaseNotFoundPageUI from './BaseNotFoundPage.ui'

interface BaseNotFoundPageProps {
  message: string
  linkDestination?: string
}
export const BaseNotFoundPage: React.FC<
  RouteComponentProps & BaseNotFoundPageProps
> = ({ message }) => {
  const { isAuthenticated } = useAuth0()
  return (
    <BaseNotFoundPageUI
      linkDestination={
        isAuthenticated ? 'https://member.butcherbox.com/member' : '/'
      }
      linkText="Butcherbox Home"
      message={message}
    />
  )
}
