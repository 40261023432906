import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import AuthenticatedLayout from '~/layouts/AuthenticatedLayout'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'
import { BaseNotFoundPage } from '~/routes/404/BaseNotFoundPage'

export default function NotFoundPage() {
  const { isAuthenticated } = useAuth0()

  return isAuthenticated ? (
    <AuthenticatedLayout title="404">
      <BaseNotFoundPage message="We can't seem to find this page. Please double-check the URL or go back to the homepage." />
    </AuthenticatedLayout>
  ) : (
    <UnauthenticatedLayout title="404">
      <BaseNotFoundPage message="We can't seem to find this page. Please double-check the URL or go back to the homepage." />
    </UnauthenticatedLayout>
  )
}
