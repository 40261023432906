import React from 'react'
import FallbackFull from '~/components/ErrorBoundary/fallbacks/Fallback.Full'

const BaseNotFoundPageUI = ({ message, linkDestination, linkText }) => {
  return (
    <FallbackFull
      errorMessage={message}
      is404
      linkDestination={linkDestination}
      linkText={linkText}
    />
  )
}

export default BaseNotFoundPageUI
